<script setup>
import {ref} from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
  {
    label: 'Home',
    items: [{label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'}]
  },
  {
    label: 'Machines',
    items: [
      {
        label: 'Line 7',
        icon: 'pi pi-fw pi-desktop',
        items: [
          { label: 'DC 1', icon: 'pi pi-fw pi-bookmark', to: '/line/7/dc/1' },
          { label: 'DC 2', icon: 'pi pi-fw pi-bookmark', to: '/line/7/dc/2' },
          { label: 'DC 3', icon: 'pi pi-fw pi-bookmark', to: '/line/7/dc/3' },
          { label: 'DC 4', icon: 'pi pi-fw pi-bookmark', to: '/line/7/dc/4' },
          { label: 'DC 5', icon: 'pi pi-fw pi-bookmark', to: '/line/7/dc/5' },
          { label: 'DC 6', icon: 'pi pi-fw pi-bookmark', to: '/line/7/dc/6' },
          { label: 'DC 7', icon: 'pi pi-fw pi-bookmark', to: '/line/7/dc/7' },
        ]
      },
    ]
  },
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in model" :key="item">
      <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
      <li v-if="item.separator" class="menu-separator"></li>
    </template>
    <li>
      <div class="flex flex-column align-items-center mt-8">
        <a href="https://vmtech.co.id/" target="_blank">
          <img src="@/assets/img/vmtech.jpeg" alt="" class="w-full mt-3" style="max-width: 70px"/>
        </a>
        <small class="mt-1">Powered By: VMTECH</small>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" scoped></style>
