import { useMQTT } from 'mqtt-vue-hook'
const mqttHook = useMQTT()

export default function initMqtt() {
    //`ws://103.189.234.55:8083/mqtt`

    const protocol = 'ws'
    const host = '103.189.234.55'
    const port = '8083'
    const url = `${protocol}://${host}:${port}/mqtt`

    // const protocol = 'ws'
    // const host = window.location.host
    // const ipWort = host.split(':')
    // const ip = ipWort[0]
    // const port = '8083'
    // const url = `${protocol}://${ip}:${port}/mqtt`

    mqttHook.connect(url, {
        clean: false,
        keepalive: 60,
        clientId: `oeedashboard_${Math.random().toString(16).substring(2, 10)}`,
        connectTimeout: 4000,
        reconnectPeriod: 1000
    })
}