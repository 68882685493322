<script setup lang="ts">
import {ref, computed, onMounted, onBeforeUnmount} from 'vue'
// @ts-ignore
import {useLayout} from '@/layout/composables/layout'
import {useRouter} from 'vue-router'
import {useMQTT} from "mqtt-vue-hook"
import {SubscribeOptions} from "mqtt-vue-hook/dist/base-hook"
import { Vue3Marquee } from 'vue3-marquee'

const {layoutConfig, onMenuToggle} = useLayout()

interface Res {
  IsDowntime: boolean,
  Message: string
}

const outsideClickListener = ref(null)
const topbarMenuActive = ref(false)
const router = useRouter()

const res = ref<Res>({
  IsDowntime: false,
  Message: ''
})

onMounted(() => {
  bindOutsideClickListener()

  const mqttHook = useMQTT()
  mqttHook.subscribe([
    "sas/tangerang/web/notif/downtime/7",
  ], 1, { properties: { userProperties: { } }, } as SubscribeOptions, () => {
    console.log('subscribed! ' + "sas/tangerang/web/notif/downtime/7")
  })

  mqttHook.registerEvent("sas/tangerang/web/notif/downtime/7", (topic: string, message: string) => {
    res.value = JSON.parse(message.toString()) as Res
  })
})

onBeforeUnmount(() => {
  unbindOutsideClickListener()
})

const logoUrl = computed(() => {
  return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`
})

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value
}
const onSettingsClick = () => {
  topbarMenuActive.value = false
  router.push('/documentation')
}
const topbarMenuClasses = computed(() => {
  return {
    'layout-topbar-menu-mobile-active': topbarMenuActive.value
  }
})

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    // @ts-ignore
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false
      }
    }
    // @ts-ignore
    document.addEventListener('click', outsideClickListener.value)
  }
}
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    // @ts-ignore
    document.removeEventListener('click', outsideClickListener)
    outsideClickListener.value = null
  }
}
// @ts-ignore
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return

  const sidebarEl = document.querySelector('.layout-topbar-menu')
  const topbarEl = document.querySelector('.layout-topbar-menu-button')

  // @ts-ignore
  return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target))
}
</script>

<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img src="@/assets/img/logo.png" alt="logo"/>
      <span>PT Sinar AlumSarana</span>
    </router-link>

    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
      <i class="pi pi-bars"></i>
    </button>

    <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
      <i class="pi pi-ellipsis-v"></i>
    </button>

<!--    <p class="bg-primary border-round p-2 m-2 font-bold txt-alert" v-if="res.IsDowntime">-->
<!--      {{res.Message}}-->
<!--    </p>-->

    <Vue3Marquee class="my-marquee" v-if="res.IsDowntime" duration="10">
      <p class="my-text-marquee">{{res.Message}}</p>
<!--      <p>Test Test Test Test Test Test Test Test Test Test Test Test </p>-->
    </Vue3Marquee>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
        <i class="pi pi-calendar"></i>
        <span>Calendar</span>
      </button>
      <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">
        <i class="pi pi-user"></i>
        <span>Profile</span>
      </button>
      <button @click="onSettingsClick()" class="p-link layout-topbar-button">
        <i class="pi pi-cog"></i>
        <span>Settings</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.txt-alert {
  font-size: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  background-color: red !important;
}
.my-marquee {
  background-color: red !important;
  color: white !important;
  font-size: 20px;
  font-weight: bold;
  margin: 0 16px;
  flex: 1;
  border-radius: 8px;
  overflow-y: hidden;
}
.my-text-marquee {
  padding: 8px 0;
}
</style>
