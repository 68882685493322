import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles.scss'
import templatePlugin from "@/utils/template-plugin"
import { registerLicense } from '@syncfusion/ej2-base'
import { CircularGaugePlugin  } from '@syncfusion/ej2-vue-circulargauge'
import initMqtt from "@/utils/mqtt";

initMqtt()
registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9TdEJjWH1bdHRTQGdY')
// registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0diXX5fc3VRT2ZYVEA=')

createApp(App)
    .use(store)
    .use(router)
    .use(templatePlugin)
    .use(CircularGaugePlugin)
    .mount('#app')